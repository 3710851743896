import React from 'react';

const ContactUs = () => {
  return (
    <>
      <h1>Contact Us</h1>
      <div>You can contact us at info@builtengineers.com.au</div>
    </>
  );
};

export default ContactUs;